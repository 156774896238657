import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

// Define a fallback image for Open Graph if no image is provided
const fallbackImage = 'https://yourdomain.com/default-og-image.jpg'

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  img = fallbackImage, // Default to fallback image if no img is provided
  robots,
  twitterCardContent,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const metaTags = [
          {
            property: 'og:type',
            content: 'website',
          },
          {
            name: 'robots',
            content: robots ? robots : 'index',
          },
          {
            name: 'twitter:card',
            content: twitterCardContent ? twitterCardContent : 'summary',
          },
        ]
        if (metaDescription) {
          metaTags.push({
            name: 'description',
            content: metaDescription,
          })
          metaTags.push({
            property: 'og:description',
            content: metaDescription,
          })
          metaTags.push({
            name: 'twitter:description',
            content: metaDescription,
          })
        }
        if (title) {
          metaTags.push({
            property: 'og:title',
            content: title,
          })
          metaTags.push({
            name: 'twitter:title',
            content: title,
          })
        }
        if (img) {
          metaTags.push({
            property: 'og:image',
            content: img,
          })
          metaTags.push({
            property: 'og:image:url',
            content: img.startsWith('http') ? img : `https:${img}`, // Ensure https protocol for og:image:url
          })
          metaTags.push({
            name: 'twitter:image',
            content: img.startsWith('http') ? img : `https:${img}`, // Ensure https protocol for twitter:image
          })
        }
        if (data.site.siteMetadata.author) {
          metaTags.push({
            name: 'twitter:creator',
            content: data.site.siteMetadata.author,
          })
        }
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s`}
            meta={metaTags
              .concat(
                keywords.length > 0
                  ? {
                      name: 'keywords',
                      content: keywords.join(', '),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  robots: 'index',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  img: PropTypes.string, // Ensure img is recognized as a prop
  robots: PropTypes.string,
  twitterCardContent: PropTypes.string,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
